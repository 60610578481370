@use "~@smartdesign/core/scss/main";
@use "~@smartdesign/core/css/themes/iw";
@use "~@smartdesign/core/scss/abstracts/functions.scss" as func;
@use "~@smartdesign/core/scss/elements/buttons";
@import "~@smartdesign/iconfont/dist/smartdesign-icons.css";

.background {
    background-color: func.color(grey, 100);
}

.navigation-ui-content-wrapper {
  max-width: 1072px !important;
  padding: 0 1.5rem !important;
}

.navigation-ui-footer {
  border-top: 0 !important;
}

.feedback-component {
  padding-top: 2.25rem;
}

.error-page__bg-img {
  background-image: url('~@smartdesign/core/assets/error-page/error.svg');
}
